<template>
  <BaseStrapiImageComponent
    v-if="!isSvg"
    :image="image"
  >
    <template
      #caption
      v-if="caption && showCaption"
    >
      <div :class="$style.caption">
        {{ caption }}
      </div>
    </template>
  </BaseStrapiImageComponent>

  <BaseFigureComponent
    v-else
  >
    <img
      :src="getAbsoluteFileUrl(image?.url)"
      :alt="image?.alternativeText"
      :class="$style.svg"
      :style="{
        '--aspect-ratio': aspectRatio,
      }"
    />
    <template
      #caption
      v-if="caption && showCaption"
    >
      <div :class="$style.caption">
        {{ caption }}
      </div>
    </template>
  </BaseFigureComponent>
</template>

<script setup lang="ts">
const { locale: currentLocale } = useI18n()

const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  showCaption: {
    type: Boolean,
    default: true,
  }
})

const isSvg = props?.image?.ext?.includes('svg')

const caption = computed(() => {
  return currentLocale.value === 'en' ? props?.image?.captionEn || props?.image?.caption : props?.image?.caption
})

const aspectRatio = computed(() => {
  return props?.image?.width / props?.image?.height
})

const getAbsoluteFileUrl = baseStrapiGetAbsoluteFileUrl
</script>

<style module>
.caption {
  composes: font-size-small from global;
  text-align: center;
  margin-top: var(--padding--element--top);
}

.svg {
  aspect-ratio: var(--aspect-ratio, auto);
}
</style>
